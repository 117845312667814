<div class="help-sidebar">
  <button
    class="help-button"
    iconCss="e-icons e-medium e-close"
    ejs-button
    (click)="hide()"
  ></button>
  <div class="help-sidebar-header">
    <h3>Hjälp om {{ content }}</h3>
  </div>
  <ejs-accordion expandMode="Single">
    <e-accordionitems>
      <e-accordionitem *ngFor="let helpItem of helpItems$ | async">
        <ng-template #header>
          <div>{{ helpItem.label }}</div>
        </ng-template>
        <ng-template #content>
          <iframe
            *ngIf="helpItem.youtubeId && helpItem.youtubeId.length > 0"
            width="358"
            height="228"
            [src]="getYoutubeLink(helpItem) | safe: 'resourceUrl'"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          >
          </iframe>
          <div [innerHtml]="helpItem.content"></div>
        </ng-template>
      </e-accordionitem>
    </e-accordionitems>
  </ejs-accordion>
</div>
