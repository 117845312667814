import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BreakPointRegistry } from '@angular/flex-layout';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocationDTO } from 'booking-sdk';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LocationSelectorComponent
    }
  ]
})
export class LocationSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  locations: LocationDTO[];

  @Input()
  location: LocationDTO;

  @Input()
  disabled: boolean = false;

  // @Output()
  // valueChange: EventEmitter<LocationDTO> = new EventEmitter<LocationDTO>();
  onChange = location => {};

  onTouched = () => {};

  touched = false;

  // disabled = false;

  locationFields: Object = { text: 'label', value: 'locationId' };
  currentLocationId: number;
  currentLocations: LocationDTO[];

  constructor() {}

  writeValue(location: LocationDTO) {
    this.location = location;
    if (this.locations && this.location) {
      this.updateList();
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.locations && changes.locations.currentValue) {
      this.updateList();
    }
  }

  private updateList() {
    // Copy original list
    this.currentLocations = JSON.parse(JSON.stringify(this.locations));

    if (this.location) {
      this.currentLocationId = this.location.locationId;

      // Add subject from booking to list, if it doesn't already exists
      if (!this.currentLocations.find(s => s.locationId === this.location?.locationId)) {
        this.currentLocations.push(this.location);
      }
    }
  }

  onLocationChange(args) {
    // this.valueChange.emit(args.itemData);
    this.markAsTouched();
    this.location = args.itemData;
    this.onChange(this.location);
  }
}
