import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { BookingDTO, UserDTO, BookingService } from 'booking-sdk';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export interface ActionObject {
  action: string;
  data: any;
}

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
  providers: [DatePipe]
})
export class CustomerInfoComponent implements OnInit, OnChanges {
  @Input()
  customer: UserDTO;

  @Input()
  bookings: BookingDTO[];

  @Output()
  actionChange: EventEmitter<ActionObject> = new EventEmitter<ActionObject>();

  @ViewChild('UpdateClientDialog') updateClientDialog: DialogComponent;

  selBookingInfo: BookingDTO[] = [];
  selOldBookingInfo: BookingDTO[] = [];
  isPreviousShown: boolean;
  dialogPosition: PositionDataModel = { X: 'center', Y: 'center' };
  selBookingFields: Object = {
    text: 'Subject',
    sortBy: 'fromDate',
    tooltip: 'Tooltip',
    iconCss: 'Icon',
    id: 'bookingId'
  };

  constructor(private datePipe: DatePipe, private bookingServeice: BookingService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.bookings &&
      changes.bookings.currentValue &&
      changes.bookings.currentValue !== changes.bookings.previousValue
    ) {
      this.bookings = changes.bookings.currentValue;
      this.selBookingInfoFiller();
    }
  }

  onSelectBookingInfo(arg) {
    this.actionChange.emit({ action: 'selectBooking', data: arg.data });
  }

  // 197411017978
  // SRD to fix

  // to fill the right info bookings
  selBookingInfoFiller(): void {
    // console.log('this.bookings ', this.bookings);

    let currentDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');

    // console.log('currentDateTime ', currentDateTime);

    // this.bookingServeice.getFutureBookings
    this.bookingServeice
      .getBookings(undefined, undefined, this.customer.userId, undefined, undefined, 0, 100000)
      .subscribe(bookings => {
        // console.log("bookings.content ", bookings.content);

        this.selBookingInfo = bookings.content.filter(b =>
          dayjs(b.toDate).isAfter(dayjs(currentDateTime))
        );

        this.selOldBookingInfo = bookings.content.filter(b =>
          dayjs(b.toDate).isSameOrBefore(dayjs(currentDateTime))
        );

        // console.log("this.selBookingInfo ", this.selBookingInfo);
        // console.log("this.selOldBookingInfo ", this.selOldBookingInfo);

        this.selBookingInfo.sort((a, b) =>
          a.fromDate.valueOf() < b.fromDate.valueOf()
            ? -1
            : a.fromDate.valueOf() > b.fromDate.valueOf()
            ? 1
            : 0
        ); // Ascending
        this.selOldBookingInfo.sort((a, b) =>
          a.fromDate.valueOf() < b.fromDate.valueOf()
            ? 1
            : a.fromDate.valueOf() > b.fromDate.valueOf()
            ? -1
            : 0
        ); // descending
        this.selBookingInfo = this.selBookingInfo.map(booking => this.convertToListObject(booking));
        this.selOldBookingInfo = this.selOldBookingInfo.map(booking =>
          this.convertToListObject(booking)
        );

        // console.log("this.selBookingInfo 2 ", this.selBookingInfo);
        // console.log("this.selOldBookingInfo 2 ", this.selOldBookingInfo);
      });
  }

  private convertToListObject(booking: BookingDTO) {
    return {
      ...booking,
      Subject: booking.subjects[0].label,
      Icon: booking.digital ? 'digitalIcon' : '',
      Tooltip:
        booking.location.label +
        ': ' +
        booking.advisor.firstname +
        ' ' +
        booking.advisor.lastname +
        ' @ ' +
        this.datePipe.transform(booking.fromDate, 'yyyy-MM-dd HH:mm')
    };
  }

  updateClient(): void {
    this.updateClientDialog.show();
  }

  onClientUpdated(arg): void {
    this.customer = arg;
    this.updateClientDialog.hide();
    this.actionChange.emit({ action: 'customerUpdated', data: this.customer });
  }
}
