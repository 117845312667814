<div fxLayout="column" fxLayoutAlign="start" class="sidebar">
  <div fxFlex="85" class="menu">
    <ejs-menu
      #sideMenu
      [items]="menuItems"
      orientation="Vertical"
      (select)="onSelect($event)"
      (beforeItemRender)="onBeforeItemRender($event)"
    >
      <ng-template #template let-dataSource="">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <div [class]="dataSource.iconCss"></div>
          <div>{{ dataSource.text }}</div>
          <div *ngIf="dataSource.count">
            <span class="e-badge e-badge-primary">{{ dataSource.count }}</span>
          </div>
        </div>
      </ng-template>
    </ejs-menu>
  </div>

  <div fxFlex="15" class="footer">
    <div>
      <div class="brand-image" fxLayout="row" fxLayoutAlign="center end">
        <img src="/assets/Rely-IT-logga.png" alt="RelyIT" class="image" />
      </div>
      <div class="version" fxLayout="row" fxLayoutAlign="center end">Version {{ version }}</div>
    </div>
  </div>
</div>

<!-- MENU WITH DOCKING -->
<!-- <div class="control-section" id="sidebarmenu">
  <div cssClass="menu-toolbar">
      <ejs-toolbar id="menuToolbar" cssClass="toolbar" (clicked)="toolbarCliked($event)">
          <e-items>
              <e-item prefixIcon="icon-menu" tooltipText="Menu"></e-item>
          </e-items>
      </ejs-toolbar>
  </div>
  <div class="main-menu-content" id="maintext">
      <div class="menu-content" style="height: 1000px;">
          
      </div>
  </div>
  <ejs-sidebar 
    id="menuSidebar" 
    class="sidebar-menu" 
    #sidebarMenuInstance 
    [enableDock]='enableDock'
    [dockSize]="dockSize"
    
    [width]="width" 
    [target]="target" 
    [isOpen]="true" 
    [enableGestures]="gestures"
    type="Auto"
    (close)="onSidebarClosed($event)"
    (open)="onSidebarOpened($event)"
  >
    <div class="main-menu">
        <div>
          <ejs-menu
            #sideMenu
            id="dockMenu"
            cssClass='dock-menu'
            [items]="menuItems"
            orientation="Vertical"
            (select)="onSelect($event)"
            (beforeItemRender)="onBeforeItemRender($event)"
          >
            <ng-template #template let-dataSource="">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div [class]="dataSource.iconCss"></div>
                <div>{{ dataSource.text }}</div>
                <div *ngIf="dataSource.count">
                  <span class="e-badge e-badge-primary">{{ dataSource.count }}</span>
                </div>
              </div>
            </ng-template>
          </ejs-menu>
        </div>
    </div>
  </ejs-sidebar>
  
</div> -->
