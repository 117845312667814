import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HelpItemDTO, HelpItemService } from 'booking-sdk';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-help-sidebar',
  templateUrl: './help-sidebar.component.html',
  styleUrls: ['./help-sidebar.component.scss']
})
export class HelpSidebarComponent implements OnInit {
  @Input()
  content: string;

  @Input()
  showHelp: boolean;

  @Output('showHelpChanged')
  showHelpChanged = new EventEmitter<boolean>();

  path: string;

  private onDestroy$ = new Subject();
  helpItems$: Observable<HelpItemDTO[]>;

  constructor(private router: Router, private helpItemService: HelpItemService) {}

  ngOnInit(): void {
    // Get initial route
    this.path = this.router.url;
    this.fetchHelpItems(this.path);

    // Get route updates
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe((event: NavigationEnd) => {
        this.path = event.url;
        this.fetchHelpItems(this.path);
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  fetchHelpItems(path: string) {
    // use first part of path
    let parts = path.split('/');
    if (parts.length > 1) {
      this.helpItems$ = this.helpItemService.getActiveHelpItems('/' + parts[1]);
      // this.helpItems$ = this.helpItemService.getActiveHelpItems(path).pipe(map(helpItems => helpItems.map(helpItem => { return { id: helpItem.helpitemId, text: helpItem.label}; })));
    }
  }

  hide() {
    this.showHelp = false;
    this.showHelpChanged.emit(this.showHelp);
  }

  getYoutubeLink(helpItem: HelpItemDTO) {
    return 'https://player.vimeo.com/video/' + helpItem.youtubeId;
  }
}
