import { jsDocComment } from '@angular/compiler';
import {
  Component,
  EventEmitter,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import {
  AdvisorDTO,
  AdvisorService,
  BookingDTO,
  LocationDTO,
  LocationService,
  ScheduleDTO,
  ScheduleService,
  ScheduleTypeDTO,
  SubjectDTO,
  SubjectService
} from 'booking-sdk';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import dayjs from 'dayjs';
import { PopupOpenEventArgs } from '@syncfusion/ej2-angular-schedule';

@Component({
  selector: 'app-schedule-editor',
  templateUrl: './schedule-editor.component.html',
  styleUrls: ['./schedule-editor.component.scss']
})
export class ScheduleEditorComponent implements OnInit, OnChanges {
  @Input()
  schedule: ScheduleDTO;

  @Output()
  scheduleChange: EventEmitter<ScheduleDTO> = new EventEmitter<ScheduleDTO>();
  locations: LocationDTO[];
  scheduleTypes: ScheduleTypeDTO[];
  advisors: AdvisorDTO[];
  editorToolbarSettings: ToolbarModule = { items: [] };
  form: FormGroup;
  firstDayOfWeek: 1;
  private onDestroy$ = new Subject();
  isLoadingScheduleTypes: boolean;
  isLoadingLocations: boolean;

  @Input()
  editorDialogObj: any;

  constructor(
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private advisorService: AdvisorService,
    private scheduleService: ScheduleService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      location: [this.schedule?.location],
      scheduleType: [this.schedule?.scheduleType],
      StartTime: [this.schedule?.fromDate, [Validators.required, this.validateTime()]],
      EndTime: [this.schedule?.toDate, [Validators.required, this.validateTime()]],
      Advisor: [this.schedule?.advisor],
      comment: [this.schedule?.comment]
    });

    this.form.valueChanges.subscribe(val => {
      this.scheduleChange.emit(this.form.value);
    });

    this.getLocations();
    this.getScheduleTypes();
  }

  validateTime(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let validTime = false;
      // console.log(control.value);
      try {
        // console.log("dayjs minutes ", dayjs(control.value).minute());
        if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
          validTime = true;
        }
        // const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, regionCode);
        // validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (e) {}
      // console.log("validTime", validTime);
      return validTime ? null : { wrongTime: { value: control.value } };
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.form &&
      changes.schedule &&
      changes.schedule.currentValue &&
      JSON.stringify(changes.schedule.previousValue) !==
        JSON.stringify(changes.schedule.currentValue)
    ) {
      this.form.patchValue({
        location: this.schedule.location,
        scheduleType: this.schedule.scheduleType,
        StartTime: this.schedule.fromDate,
        EndTime: this.schedule.toDate,
        comment: this.schedule.comment
      });
    }
  }

  private getScheduleTypes(): void {
    this.isLoadingScheduleTypes = true;
    this.scheduleService
      .getScheduleTypes()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        scheduleTypes => {
          this.scheduleTypes = scheduleTypes;
          // To select Kundmottagning by default
          // this.selectedScheduleType = this.scheduleTypes[1];
          this.isLoadingScheduleTypes = false;
        },
        () => (this.isLoadingScheduleTypes = false)
      );
  }

  private getLocations(): void {
    this.isLoadingLocations = true;
    this.locationService
      .getActiveLocations()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        locations => {
          this.locations = locations;
          // this.selectedLocation = this.locations[0];
          this.isLoadingLocations = false;
        },
        () => (this.isLoadingLocations = false)
      );
  }

  onStartTimeUpdated(control) {
    // console.log("control 1", control);
    // console.log("this.form ", this.form);
    // console.log("this.form.status ", this.form.status);

    // if (this.form.valid) {

    let buttons = this.editorDialogObj.buttons;

    if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
      // console.log("VALID 1");
      // Update EndTime based on StartTime

      let startTime = dayjs(this.form.value.StartTime);
      let endTime = dayjs(this.form.value.EndTime);
      const bookingLength = endTime.diff(startTime, 'minute');

      this.form.patchValue({ StartTime: dayjs(control.value).toDate() });

      // console.log("startTime 1 ", dayjs(startTime).toDate());
      // console.log("endTime 1 ", dayjs(endTime).toDate());
      // console.log("bookingLength 1 ", bookingLength);

      if (buttons) {
        buttons[0].properties.buttonModel.disabled = false;
        buttons[1].properties.buttonModel.disabled = false;
      }

      this.form.patchValue({ EndTime: dayjs(control.value).add(bookingLength, 'minute').toDate() });
    } else {
      // console.log("INVALID 1");

      // console.log("buttons", buttons);
      if (buttons) {
        buttons[0].properties.buttonModel.disabled = true;
        buttons[1].properties.buttonModel.disabled = true;
      }

      this.form.markAllAsTouched();
    }

    this.editorDialogObj.setProperties({ buttons: buttons });
  }

  onEndTimeUpdated(control) {
    // console.log("control 2", control);
    // console.log("this.form ", this.form);
    // console.log("this.form.status ", this.form.status);

    // if (this.form.valid) {

    let buttons = this.editorDialogObj.buttons;

    if (dayjs(control.value).minute() == 0 || dayjs(control.value).minute() == 30) {
      // console.log("VALID 2");

      this.form.patchValue({ EndTime: dayjs(control.value).toDate() });
      // let startTime = dayjs(this.form.value.StartTime);
      // let endTime = dayjs(this.form.value.EndTime);
      // const bookingLength = endTime.diff(startTime, 'minute');

      if (buttons) {
        buttons[0].properties.buttonModel.disabled = false;
        buttons[1].properties.buttonModel.disabled = false;
      }

      // this.form.patchValue({ EndTime: dayjs(control.value).add(bookingLength, 'minute').toDate() });
    } else {
      // console.log("INVALID 2");

      // console.log("buttons", buttons);
      if (buttons) {
        buttons[0].properties.buttonModel.disabled = true;
        buttons[1].properties.buttonModel.disabled = true;
      }

      this.form.markAllAsTouched();
    }

    this.editorDialogObj.setProperties({ buttons: buttons });
  }
}
