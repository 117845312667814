<!-- [readonly]="isReadOnly" -->
<label for="Location">Plats</label>
<ejs-dropdownlist
  id="Location"
  name="Location"
  class="e-dropdownlist e-field"
  [dataSource]="currentLocations"
  [fields]="locationFields"
  [(value)]="currentLocationId"
  (change)="onLocationChange($event)"
  [enabled]="!disabled"
></ejs-dropdownlist>
