<form [formGroup]="form" *ngIf="schedule">
  <div class="inputs" fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="column">
      <app-schedule-type-selector
        [scheduleTypes]="scheduleTypes"
        formControlName="scheduleType"
      ></app-schedule-type-selector>
    </div>

    <div fxLayout="column">
      <label for="StartTime">Från</label>
      <ejs-datetimepicker
        id="StartTime"
        name="StartTime"
        [class.e-error]="form.get('StartTime').invalid && form.get('StartTime').touched"
        class="e-field e-input"
        formControlName="StartTime"
        format="yyyy-MM-dd HH:mm"
        timeFormat="HH:mm"
        [allowEdit]="false"
        [firstDayOfWeek]="firstDayOfWeek"
        (change)="onStartTimeUpdated($event)"
      ></ejs-datetimepicker>
      <div
        fxFlexOffset="5"
        *ngIf="form.get('StartTime').invalid && form.get('StartTime').touched"
        class="error-message"
      >
        Endast hel eller halvtimme dvs. 00 eller 30 minuter
      </div>
    </div>

    <div fxFlex="50%" fxLayout="column">
      <label for="EndTime">Till</label>
      <ejs-datetimepicker
        id="EndTime"
        name="EndTime"
        [class.e-error]="form.get('EndTime').invalid && form.get('EndTime').touched"
        class="e-field e-input"
        formControlName="EndTime"
        format="yyyy-MM-dd HH:mm"
        timeFormat="HH:mm"
        [allowEdit]="false"
        [firstDayOfWeek]="firstDayOfWeek"
        (change)="onEndTimeUpdated($event)"
      ></ejs-datetimepicker>
      <div
        fxFlexOffset="5"
        *ngIf="form.get('EndTime').invalid && form.get('EndTime').touched"
        class="error-message"
      >
        Endast hel eller halvtimme dvs. 00 eller 30 minuter
      </div>
    </div>

    <div fxLayout="column">
      <app-location-selector
        [disabled]="false"
        [locations]="locations"
        formControlName="location"
      ></app-location-selector>
    </div>

    <div fxFlex="50%" fxLayout="column">
      <label for="Comment">Kommentar</label>
      <textarea
        id="Comment"
        class="e-field e-input"
        name="comment"
        formControlName="comment"
      ></textarea>

      <!-- <label for="Comment">Kommentar</label>
      <ejs-richtexteditor
        id="Comment"
        name="Comment"
        formControlName="Comment"
        [enableResize]="true"
        [toolbarSettings]="editorToolbarSettings"
      ></ejs-richtexteditor> -->
    </div>
  </div>
</form>
